<template>
  <el-card class="v_user_cas_out_list">
    <div class="g-flex"  slot="header">
      <span>用户资金流水记录</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="exportClick">导出列表</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="资金类别">
        <el-select v-model="query.category" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="本金结算" value="1"></el-option>
          <el-option label="佣金结算" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="资金类型">
        <el-select v-model="query.type" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="订单" value="4"></el-option>
          <el-option label="提现" value="2"></el-option>
          <el-option label="推广" value="5"></el-option>
        </el-select>
      </el-form-item>
      

      <el-form-item label="时间">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>       

      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="用户ID" value="user_id"></el-option>
          <el-option label="手机号" value="mobile"></el-option>
          <el-option label="姓名" value="name"></el-option>
          <el-option label="昵称" value="nick_name"></el-option>
          <el-option label="微信" value="wechat"></el-option>
          <el-option label="QQ" value="qq"></el-option>
        </el-select>

        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="small" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="用户手机号" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user_id" label="用户ID" width="100" :show-overflow-tooltip="true"/>
      <el-table-column prop="key_id" label="记录编号" width="120" :show-overflow-tooltip="true"/>
      <el-table-column label="资金类别" width="100" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14 g-red" v-show="scope.row.category === 1">本金结算</span>
          <span class="g-fsize14 g-green" v-show="scope.row.category === 2">佣金结算</span>
        </template>
      </el-table-column>

      <el-table-column label="类型" width="80" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14">{{scope.row.type | filtersType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标题" width="160" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14">{{scope.row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="变动金额" width="120" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14 g-yellow">{{scope.row.money}}</span>
        </template>
      </el-table-column>
      <el-table-column label="变更后余额" width="160" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="g-fsize14 g-yellow">{{scope.row.balance}}元</span>
          </template>
      </el-table-column>
      <el-table-column label="用户姓名" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户昵称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.nick_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="微信" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.wechat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="QQ" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.qq}}</span>
        </template>
      </el-table-column>
        <el-table-column label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>


    <user-money-list-export-pop ref="UserMoneyListExportPop"/>

  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetUserMoneyList } from '@/utils/api.js'
  import UserMoneyListExportPop from './UserMoneyListExportPop.vue'
  export default {
    name: 'userMoneyList',
    components: { UserMoneyListExportPop },
    data() {
      return {
        
        auth: {
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        // type  0-其他 1-充值 2-提现 3-任务  4-任务订单 5-推广结算
        // category 1本金结算  2佣金结算
        query: { search_key: 'mobile', search_val: '', page:1, limit: 15, type: 'ALL', category:'ALL', date_time: [] },

        table:{ loading:false, total:0, rows:[] },


        options: [
          {
            value: 0,
            label: '长期屏蔽'
          },
          {
            value: 60 * 30,
            label: '屏蔽半小时'
          }, 
          {
            value: 60 * 60,
            label: '屏蔽一小时'
          }, 
          {
            value: 60 * 60 * 2,
            label: '屏蔽两小时'
          }, 
          {
            value: 60 * 60 * 6,
            label: '屏蔽六小时'
          }, 
          {
            value:  60 * 60 * 24,
            label: '屏蔽一天'
          }],
      }
    },
    created() {
      this.getList()
    },
    filters: {
      filtersType(type) {
        if(type == 1) return '充值'
        if(type == 2) return '提现'
        if(type == 3) return '任务'
        if(type == 4) return '订单'
        if(type == 5) return '推广'
        return '后台操作'
      }
    },
    methods: {
      formatDate,
      // 点击导出
      exportClick() {
        this.$refs.UserMoneyListExportPop.onShow()
      },
      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetUserMoneyList(this.query)
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
        if(!success) return
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      // 点击重置
      resetData() {
        this.query = { search_key: 'user_id', search_val: '', page:1, limit: 15, type: 'ALL', category:'ALL', date_time: [] }
        this.getList()
      }
    }
  }
</script>

<style lang="scss">
</style>